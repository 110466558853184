export class WrongEmailOrPasswordError extends Error {
  public constructor() {
    super('Wrong email or password');
  }
}

export class AccountLockedError extends Error {
  public constructor() {
    super('Account is locked');
  }
}

export class AccountInactiveError extends Error {
  public constructor() {
    super('Account is inactive');
  }
}

export class AccountSuspendedError extends Error {
  public constructor() {
    super('Account is suspended');
  }
}

export class TechnicalUserCannotLoginToUIError extends Error {
  public constructor() {
    super('Technical users cannot log in to the UI');
  }
}

export class AccountPendingError extends Error {
  public constructor() {
    super('Account is pending');
  }
}

export class SuperAccountPendingError extends Error {
  public constructor() {
    super('Super account is pending');
  }
}

export class ErrorWhileCreatingUserError extends Error {
  public constructor() {
    super('Error while creating user');
  }
}

export class UserEmailAlreadyExistError extends Error {
  public constructor() {
    super('User email already exists');
  }
}

export class EulaNotAcceptedError extends Error {
  public constructor() {
    super('User agreement not accepted');
  }
}

export class TokenNotValidError extends Error {
  public constructor() {
    super('Token is not valid');
  }
}

export class EmailNotValidError extends Error {
  public constructor() {
    super('Email is not valid');
  }
}

export class AccountAlreadyActive extends Error {
  public constructor() {
    super('Account is already active');
  }
}
