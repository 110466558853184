import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RegisterUserModel } from '@repository/authentication/models/register-user.model';
import { ResetPasswordModel } from '@repository/authentication/models/reset-password.model';
import { ActionResponse, LoginResponse, VerifyEmailResponse } from '../../types/DataResult';
import { RESTServerRoute } from '../../types/Server';

@Injectable()
export class AuthenticationApiService {
  public constructor(private readonly httpClient: HttpClient) {}

  public login(email: string, password: string, acceptEula: boolean, tenant: string): Observable<LoginResponse> {
    return this.httpClient.post<LoginResponse>(RESTServerRoute.REST_SIGNIN, { email, password, acceptEula, tenant });
  }

  public register(user: RegisterUserModel): Observable<ActionResponse> {
    return this.httpClient.post<ActionResponse>(RESTServerRoute.REST_SIGNON, user);
  }

  public resetPassword(data: ResetPasswordModel): Observable<ActionResponse> {
    return this.httpClient.post<ActionResponse>(RESTServerRoute.REST_PASSWORD_RESET, data);
  }

  public definePassword(password: string, hash: string, tenant: string): Observable<ActionResponse> {
    return this.httpClient.post<ActionResponse>(RESTServerRoute.REST_PASSWORD_RESET, { password, hash, tenant });
  }

  public verifyEmail(data: {
    Email: string;
    VerificationToken: string;
    Tenant: string;
  }): Observable<VerifyEmailResponse> {
    return this.httpClient.get<ActionResponse>(RESTServerRoute.REST_MAIL_CHECK, {
      params: data,
    });
  }

  public resendVerificationEmail(data: { email: string; tenant: string; captcha: string }): Observable<ActionResponse> {
    return this.httpClient.post<ActionResponse>(RESTServerRoute.REST_MAIL_RESEND, data);
  }
}
